import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { anilineCompanyIds } from '../../settings';
import capitalize from '../../helpers/capitalize';
import { db } from '../../redux/firestore';
import useCompanyRole from '../../hooks/useCompanyRole';
import usePlanAndUserRules from '../../hooks/usePlanAndUserRules';

const { logout, slideOut } = authAction;

const TopbarUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [creditsRest, setCreditsRest] = useState(null);
  const companyRole = useCompanyRole();
  const { planData } = usePlanAndUserRules();

  const subscription_plan = planData?.type || '';

  const { name = '', companyId, mainCompanyId } = useSelector(({ Auth: { claims } }) => {
    return claims || {};
  });

  useEffect(() => {
    const docRef = db
      .collection('companies')
      .doc(`${companyId}`)
      .collection('license')
      .doc('credits');

    docRef.onSnapshot(doc => {
      if (doc.exists) {
        setCreditsRest(doc.data());
      } else {
        console.log('No such document!');
      }
    });
  }, [companyId]);

  const isSlideIn = anilineCompanyIds.some(company => company === companyId);
  const isSlideOut = !isSlideIn && anilineCompanyIds.some(company => company === mainCompanyId);

  const onLogout = () => {
    history.replace({
      search: '',
      pathname: '/',
    });
    window.Intercom('shutdown');
    dispatch(logout());
    setVisible(!visible);
  };

  const onSlideIn = () => {
    history.push('/impersonate');
    setVisible(!visible);
  };

  const onUserManagement = () => {
    history.push('/user-management');
    setVisible(!visible);
  };

  const onSlideOut = () => {
    dispatch(slideOut());
    setVisible(!visible);
  };

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      console.error('Intercom is not initialized');
    }
  };

  const setRing = () => {
    if (subscription_plan === 'pro') {
      return 'pro';
    }

    if (subscription_plan === 'enterprise') {
      return 'enter';
    }

    if (subscription_plan === 'basic') {
      return 'basic';
    }

    if (subscription_plan === 'starter') {
      return 'starter';
    }

    return '';
  };

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      {subscription_plan && (
        <div data-cy="profile_item_plan" className="plan">
          <span onClick={openIntercom} tabIndex={0} role="button" className={`${subscription_plan}`}>
            {capitalize(subscription_plan)}
          </span>
        </div>
      )}

      {/* <span role="button" tabIndex={-1} className="isoDropdownLink" onClick={onSupport}> */}
      {/*  <span className="icon icon-help-circle" /> <IntlMessages id="topbar.support" /> */}
      {/* </span> */}

      {isSlideIn && (
        <span
          data-cy="profile_item_impersonate"
          role="button"
          tabIndex={-3}
          className="isoDropdownLink"
          onClick={onSlideIn}
        >
          <span className="icon icon-p-impersonate" /> Impersonate
        </span>
      )}
      {!isSlideOut && companyRole === 'admin' && (
        <span
          data-cy="profile_item_user_management"
          role="button"
          tabIndex={-3}
          className="isoDropdownLink"
          onClick={onUserManagement}
        >
          <span className="icon icon-p-users" /> User Management
        </span>
      )}
      {isSlideOut && (
        <span
          data-cy="profile_item_user_impersonate_exit"
          role="button"
          tabIndex={-4}
          className="isoDropdownLink"
          onClick={onSlideOut}
        >
          <span className="icon icon-p-impersonate-exit" /> Exit
        </span>
      )}
      <span
        data-cy="profile_item_user_logout"
        role="button"
        tabIndex={-5}
        className="isoDropdownLink"
        onClick={onLogout}
      >
        <span className="icon log-out icon-p-log-out" /> <IntlMessages id="topbar.logout" />
      </span>
    </TopbarDropdownWrapper>
  );

  return [
    <div className="user-info-box">
      <span data-cy="profile_item_user_name" className="user-name">
        {name}
      </span>
      {creditsRest && creditsRest.rest !== null && (
        <div data-cy="company-credits" className="company-credits">
          {/* {creditsRest.rest} credits */}
          {creditsRest.total ? `${creditsRest.used} / ${creditsRest.total}` : creditsRest.used} credits
        </div>
      )}
    </div>,
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={() => {
        setVisible(!visible);
      }}
      arrowPointAtCenter
      placement="bottomLeft"
    >
      <div data-cy="profile_item_user_avatar" className="isoImgWrapper">
        <img
          className={setRing()}
          alt={name}
          src={`https://eu.ui-avatars.com/api/?name=${name}&background=ffffff&color=000`}
        />
        {isSlideOut && (
          <span data-cy="profile_item_user_impersonate_online" className="userActivity online">
            Imp
          </span>
        )}
      </div>
    </Popover>,
  ];
};

export default TopbarUser;
